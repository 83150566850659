"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.COOKIE_HANDOFF_CREDENTIALS = exports.COOKIE_LAST_FLOW = exports.COOKIE_MOCKED_FLOW_EXPORT = exports.COOKIE_MOCKDATA_SELECTED_SCENARIO = exports.COOKIE_LOG_VALIDATION = exports.COOKIE_LOG_ALL_EXPRESSIONS = exports.COOKIE_DISABLE_RELOAD = exports.COOKIE_DISABLE_EXPRESSION_WARNINGS = exports.COOKIE_DEVELOPMENT = exports.COOKIE_AUTH_SEPARATOR = exports.COOKIE_AUTH = void 0;
exports.COOKIE_AUTH = 'auth';
exports.COOKIE_AUTH_SEPARATOR = '|';
exports.COOKIE_DEVELOPMENT = 'development';
exports.COOKIE_DISABLE_EXPRESSION_WARNINGS = 'disable_expression_warnings';
exports.COOKIE_DISABLE_RELOAD = 'disable_reload';
exports.COOKIE_LOG_ALL_EXPRESSIONS = 'log_all_expressions';
exports.COOKIE_LOG_VALIDATION = 'log_validation';
exports.COOKIE_MOCKDATA_SELECTED_SCENARIO = 'mockdata_selected_scenario';
exports.COOKIE_MOCKED_FLOW_EXPORT = 'mocked_flow_export';
exports.COOKIE_LAST_FLOW = 'last-flow';
exports.COOKIE_HANDOFF_CREDENTIALS = 'handoff-credentials';
