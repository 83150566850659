"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.matchTags = void 0;
const utils_1 = require("@zenoo/hub-client-utils/utils");
const util_1 = require("util");
const constants_1 = require("../workflow/constants");
const C = __importStar(require("./constants"));
const utils_2 = require("./utils");
const initialState = {
    data: {},
    formErrors: {},
    globalErrors: [],
    isValid: true,
    validateOnLoad: true,
    validating: false,
    validationErrors: {},
    validationInitialized: false,
    visited: {},
    visualTags: [],
};
exports.matchTags = (tag, compare) => {
    return !!(util_1.isArray(compare) ? compare : [compare]).find(compareTag => {
        const res = tag.match(new RegExp(compareTag));
        return res && res.length === 1 && res[0].length === tag.length;
    });
};
exports.default = (state = initialState, action) => {
    switch (action.type) {
        case C.FORM_DATA_SET:
            return Object.assign(Object.assign({}, state), { data: Object.assign(Object.assign({}, state.data), action.data) });
        case C.FORM_DATA_VALUE_SET:
            const data = Object.assign({}, state.data);
            utils_1.setByExpression(data, action.fieldName, action.value);
            return Object.assign(Object.assign({}, state), { data });
        case C.FORM_VISITED_SET:
            return Object.assign(Object.assign({}, state), { visited: Object.assign(Object.assign({}, state.visited), { [action.fieldName]: true }) });
        case C.FORM_TAGS_ADD:
            return Object.assign(Object.assign({}, state), { visualTags: [...state.visualTags].concat(action.tags.filter(tag => state.visualTags.indexOf(tag) === -1)) });
        case C.FORM_TAGS_REMOVE:
            return Object.assign(Object.assign({}, state), { visualTags: [...state.visualTags.filter(tag => !exports.matchTags(tag, action.tags))] });
        case C.FORM_TAGS_RESET:
            return Object.assign(Object.assign({}, state), { visualTags: [] });
        case C.FORM_DATA_RESET:
            return Object.assign(Object.assign({}, state), { data: null, visited: {} });
        case constants_1.FLOW_SUBMIT_VALIDATION_ERROR:
            const { fieldErrors, globalErrors } = utils_2.processValidationErrors(action.errors);
            return Object.assign(Object.assign({}, state), { validationErrors: fieldErrors, globalErrors });
        case C.FORM_DATA_VALIDATE_START:
            return Object.assign(Object.assign({}, state), { validating: true });
        case C.FORM_DATA_VALIDATE_ERROR:
            return Object.assign(Object.assign({}, state), { validating: false });
        case C.FORM_DATA_VALIDATE_SUCCESS:
            return Object.assign(Object.assign({}, state), { validating: false, validationErrors: {}, globalErrors: [], isValid: utils_1.isEmptyObject(state.formErrors) });
        case C.FORM_VALIDATION_INITIALIZED: {
            return Object.assign(Object.assign({}, state), { validationInitialized: true });
        }
        case C.FORM_DATA_VALIDATE_VALIDATION_ERROR:
            return Object.assign(Object.assign({}, state), { validating: false, validationErrors: action.fieldErrors, globalErrors: action.globalErrors, isValid: false });
        case C.FORM_ERROR_SET:
            const errors = Object.assign(Object.assign({}, state.formErrors), { [action.fieldName]: action.error || '' });
            utils_1.clean(errors);
            return Object.assign(Object.assign({}, state), { formErrors: errors, isValid: utils_1.isEmptyObject(errors) && utils_1.isEmptyObject(state.validationErrors) });
        case C.FORM_CLEAR_ERRORS:
            return Object.assign(Object.assign({}, state), { validationErrors: action.allErrors ? {} : state.validationErrors, formErrors: action.allErrors ? {} : state.formErrors, globalErrors: [] });
        default:
            return state;
    }
};
