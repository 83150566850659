"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnexpectedStatusError = void 0;
class UnexpectedStatusError extends Error {
    constructor(message, response) {
        super(message);
        this.response = response;
        const currentProto = new.target.prototype;
        if (Object.setPrototypeOf) {
            Object.setPrototypeOf(this, currentProto);
        }
        else {
            ;
            this.__proto__ = currentProto;
        }
    }
}
exports.UnexpectedStatusError = UnexpectedStatusError;
