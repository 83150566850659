"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Endpoints = exports.expectStatus = exports.endpointUrl = void 0;
const hub_client_translations_1 = require("@zenoo/hub-client-translations");
const utils_1 = require("@zenoo/hub-client-utils/utils");
const Errors_1 = require("../../utils/Errors");
exports.endpointUrl = {
    INIT: `{SERVER_URL}/executor/{FLOW_NAME}`,
    STATUS: `{SERVER_URL}/execution/{EXECUTION_UUID}`,
    SUBMIT: `{SERVER_URL}/execution/{EXECUTION_UUID}`,
    FILE_CACHE: `{SERVER_URL}/files/cache`,
    BACK: `{SERVER_URL}/execution/{EXECUTION_UUID}/back`,
    FILE: `{SERVER_URL}/files/{UUID}`,
    FUNCTION_EXECUTE: `{SERVER_URL}/execution/{EXECUTION_UUID}/function/`,
    FUNCTION_RESULT: `{SERVER_URL}/execution/{EXECUTION_UUID}/function/{FUNCTION_UUID}`,
};
function expectStatus(data, expectedStatus = utils_1.HTTP_STATUS.OK) {
    if ((Array.isArray(expectedStatus) ? expectedStatus : [expectedStatus]).indexOf(data.status) === -1) {
        throw new Errors_1.UnexpectedStatusError(hub_client_translations_1.translate('API_REQUEST_FAILED', { expectedStatus: expectedStatus.toString(), dataStatus: data.status }), data);
    }
}
exports.expectStatus = expectStatus;
class Endpoints {
    static init(serverUrl, flowName, payload) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = utils_1.buildUrl(exports.endpointUrl.INIT, {
                SERVER_URL: serverUrl,
                FLOW_NAME: flowName,
            });
            const response = yield utils_1.Request.post(url, null, {
                payload: payload,
            });
            expectStatus(response, utils_1.HTTP_STATUS.CREATED);
            if (!utils_1.isString(response.data.token)) {
                throw new hub_client_translations_1.CoreError('API_CAN_NOT_PARSE_TOKEN');
            }
            if (!utils_1.isString(response.data.uuid)) {
                throw new hub_client_translations_1.CoreError('API_CAN_NOT_PARSE_EXEC');
            }
            return {
                authToken: response.data.token,
                executionUuid: response.data.uuid,
            };
        });
    }
    static status(serverUrl, executionUuid, authToken) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = utils_1.buildUrl(exports.endpointUrl.STATUS, {
                SERVER_URL: serverUrl,
                EXECUTION_UUID: executionUuid,
            });
            const response = yield utils_1.Request.get(url, authToken);
            if (response.status === utils_1.HTTP_STATUS.NOT_FOUND) {
                throw new hub_client_translations_1.CoreError('API_REQUEST_FAILED_404');
            }
            expectStatus(response);
            return response.data;
        });
    }
    static submit(serverUrl, executionUuid, authToken, routeUuid, payload) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = utils_1.buildUrl(exports.endpointUrl.SUBMIT, {
                SERVER_URL: serverUrl,
                EXECUTION_UUID: executionUuid,
            });
            const response = yield utils_1.Request.post(url, authToken, {
                uuid: routeUuid,
                payload: payload,
            });
            if (response.status === utils_1.HTTP_STATUS.NOT_FOUND) {
                throw new hub_client_translations_1.CoreError('API_REQUEST_FAILED_404');
            }
            expectStatus(response, [utils_1.HTTP_STATUS.OK, utils_1.HTTP_STATUS.ACCEPTED]);
            return {
                processing: response.status === utils_1.HTTP_STATUS.ACCEPTED,
                response: response.data,
            };
        });
    }
    static cacheFile(serverUrl, file, authToken, progressCall) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = utils_1.buildUrl(exports.endpointUrl.FILE_CACHE, {
                SERVER_URL: serverUrl,
            });
            const formData = new FormData();
            formData.append('file', file);
            const response = yield utils_1.Request.requestWithProgress(url, utils_1.HTTP_METHOD.POST, authToken, formData, false, progressCall);
            expectStatus(response, utils_1.HTTP_STATUS.CREATED);
            return response.data;
        });
    }
    static flowBack(serverUrl, executionUuid, authToken, routeUuid, payload) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = utils_1.buildUrl(exports.endpointUrl.BACK, {
                SERVER_URL: serverUrl,
                EXECUTION_UUID: executionUuid,
            });
            const response = yield utils_1.Request.post(url, authToken, {
                uuid: routeUuid,
                payload: payload,
            });
            expectStatus(response);
            return response.data;
        });
    }
    static getFile(serverUrl, authToken, fileId) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = utils_1.buildUrl(exports.endpointUrl.FILE, {
                SERVER_URL: serverUrl,
                UUID: fileId,
            });
            const response = yield utils_1.Request.base(url, utils_1.HTTP_METHOD.GET, authToken, null, false, true);
            expectStatus(response);
            return response.data.blob();
        });
    }
    static functionExecute(serverUrl, executionUuid, authToken, functionName, payload) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = utils_1.buildUrl(exports.endpointUrl.FUNCTION_EXECUTE, {
                SERVER_URL: serverUrl,
                EXECUTION_UUID: executionUuid,
            });
            const response = yield utils_1.Request.post(url, authToken, {
                name: functionName,
                payload: payload,
            });
            expectStatus(response, [utils_1.HTTP_STATUS.CREATED]);
            return response.data;
        });
    }
    static functionResult(serverUrl, executionUuid, authToken, functionUuid) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = utils_1.buildUrl(exports.endpointUrl.FUNCTION_RESULT, {
                SERVER_URL: serverUrl,
                EXECUTION_UUID: executionUuid,
                FUNCTION_UUID: functionUuid,
            });
            const response = yield utils_1.Request.get(url, authToken);
            expectStatus(response, [utils_1.HTTP_STATUS.OK]);
            return response.data;
        });
    }
}
exports.Endpoints = Endpoints;
