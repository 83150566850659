"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.processValidationErrors = void 0;
exports.processValidationErrors = (errors) => {
    const globalErrors = [];
    const fieldErrors = errors.reduce((res, key) => {
        if (!key.field) {
            globalErrors.push(key.message);
        }
        else {
            return Object.assign(Object.assign({}, res), { [key.field]: key.message });
        }
    }, {});
    return { globalErrors, fieldErrors };
};
