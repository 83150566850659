"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.refreshAuthCookieExpiration = exports.stopRefreshAuthCookieExpiration = exports.startRefreshAuthCookieExpiration = exports.removeAuthCookie = exports.setAuthCookie = exports.getAuthCookie = exports.getAuthData = exports.parseAuthData = void 0;
const utils_1 = require("@zenoo/hub-client-utils/utils");
const Cookies = __importStar(require("js-cookie"));
const cookie_1 = require("../constants/cookie");
const embed_1 = require("./embed");
exports.parseAuthData = (authCookie) => {
    if (!authCookie) {
        return null;
    }
    const [executionUuid, authToken] = authCookie.split(cookie_1.COOKIE_AUTH_SEPARATOR);
    return { executionUuid, authToken };
};
exports.getAuthData = () => {
    return exports.parseAuthData(exports.getAuthCookie());
};
exports.getAuthCookie = () => utils_1.safe(() => Cookies.get(cookie_1.COOKIE_AUTH), '');
exports.setAuthCookie = (executionUuid, authToken, authorizationTimeout = 10) => {
    const isInIframe = embed_1.getIsInIframe();
    const isSessionCookie = authorizationTimeout === 0;
    const authCookie = `${executionUuid}${cookie_1.COOKIE_AUTH_SEPARATOR}${authToken}`;
    const expires = new Date();
    expires.setMinutes(expires.getMinutes() + authorizationTimeout);
    if (isInIframe) {
        embed_1.setParrentAuthData(authCookie, isSessionCookie ? undefined : expires.toISOString());
    }
    Cookies.set(cookie_1.COOKIE_AUTH, authCookie, {
        sameSite: isInIframe ? 'none' : undefined,
        secure: isInIframe,
        expires: isSessionCookie ? undefined : expires,
        path: window.location.pathname,
    });
};
exports.removeAuthCookie = () => {
    Cookies.remove(cookie_1.COOKIE_AUTH);
};
let cookieRefresher;
exports.startRefreshAuthCookieExpiration = (authorizationTimeout) => {
    if (cookieRefresher) {
        clearInterval(cookieRefresher);
    }
    cookieRefresher = setTimeout(() => exports.refreshAuthCookieExpiration(authorizationTimeout), 10000);
};
exports.stopRefreshAuthCookieExpiration = () => {
    clearInterval(cookieRefresher);
};
exports.refreshAuthCookieExpiration = (authorizationTimeout) => __awaiter(void 0, void 0, void 0, function* () {
    let authData = null;
    if (embed_1.getIsInIframe()) {
        authData = exports.parseAuthData(yield embed_1.getParrentAuthData());
    }
    else {
        authData = exports.getAuthData();
    }
    if (!authData || !authData.executionUuid || !authData.authToken) {
        window.location.reload();
        return;
    }
    exports.setAuthCookie(authData.executionUuid, authData.authToken, authorizationTimeout);
    cookieRefresher = setTimeout(() => exports.refreshAuthCookieExpiration(authorizationTimeout), 10000);
});
