"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ANALYTICS_RESOLVE_PARAMS = exports.ANALYTICS_CHANGE_PAGE_SUCCESS = exports.ANALYTICS_CHANGE_PAGE_ERROR = exports.ANALYTICS_CHANGE_PAGE_START = exports.ANALYTICS_AUTHORIZATION_SUCCESS = exports.ANALYTICS_AUTHORIZATION_ERROR = exports.ANALYTICS_AUTHORIZATION_START = exports.ANALYTICS_CUSTOM_EVENT_SUCCESS = exports.ANALYTICS_CUSTOM_EVENT_ERROR = exports.ANALYTICS_CUSTOM_EVENT_START = exports.ANALYTICS_INIT_SUCCESS = exports.ANALYTICS_INIT_ERROR = exports.ANALYTICS_INIT_START = void 0;
exports.ANALYTICS_INIT_START = 'ANALYTICS_INIT_START';
exports.ANALYTICS_INIT_ERROR = 'ANALYTICS_INIT_ERROR';
exports.ANALYTICS_INIT_SUCCESS = 'ANALYTICS_INIT_SUCCESS';
exports.ANALYTICS_CUSTOM_EVENT_START = 'ANALYTICS_CUSTOM_EVENT_START';
exports.ANALYTICS_CUSTOM_EVENT_ERROR = 'ANALYTICS_CUSTOM_EVENT_ERROR';
exports.ANALYTICS_CUSTOM_EVENT_SUCCESS = 'ANALYTICS_CUSTOM_EVENT_SUCCESS';
exports.ANALYTICS_AUTHORIZATION_START = 'ANALYTICS_AUTHORIZATION_START';
exports.ANALYTICS_AUTHORIZATION_ERROR = 'ANALYTICS_AUTHORIZATION_ERROR';
exports.ANALYTICS_AUTHORIZATION_SUCCESS = 'ANALYTICS_AUTHORIZATION_SUCCESS';
exports.ANALYTICS_CHANGE_PAGE_START = 'ANALYTICS_CHANGE_PAGE_START';
exports.ANALYTICS_CHANGE_PAGE_ERROR = 'ANALYTICS_CHANGE_PAGE_ERROR';
exports.ANALYTICS_CHANGE_PAGE_SUCCESS = 'ANALYTICS_CHANGE_PAGE_SUCCESS';
exports.ANALYTICS_RESOLVE_PARAMS = 'ANALYTICS_RESOLVE_PARAMS';
