"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UniversalStorage = void 0;
const utils_1 = require("@zenoo/hub-client-utils/utils");
class UniversalStorage {
    constructor() {
        this.storageFunctions = {
            increment: (propertyName) => {
                if (!utils_1.isNumber(this.storageData[propertyName])) {
                    this.storageData[propertyName] = 0;
                }
                return ++this.storageData[propertyName];
            },
            get: (propertyName, defaultValue) => {
                const result = utils_1.getByExpression(this.storageData, propertyName, defaultValue);
                if (result === undefined) {
                    return defaultValue;
                }
                return result;
            },
            set: (propertyName, value) => utils_1.setByExpression(this.storageData, propertyName, value),
        };
        this.storageData = {};
        this.storageProxy = new Proxy({}, {
            get: (_, key) => this.storageFunctions[key] || this.storageData[key],
            set: (_, key, value) => {
                if (Object.keys(this.storageFunctions).indexOf(key) === -1) {
                    return (this.storageData[key] = value);
                }
                return false;
            },
        });
    }
    get storage() {
        return this.storageProxy;
    }
    reset() {
        this.storageData = {};
    }
}
exports.UniversalStorage = UniversalStorage;
