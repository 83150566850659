"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFile = exports.apiFunction = exports.back = exports.submit = exports.status = exports.refresh = exports.init = void 0;
const hub_client_translations_1 = require("@zenoo/hub-client-translations");
const utils_1 = require("@zenoo/hub-client-utils/utils");
const Endpoints_1 = require("../../../../api/v0/Endpoints");
const auth_1 = require("../../../../utils/auth");
const promise_1 = require("../../../../utils/promise");
const promise_2 = require("../../../../utils/promise");
const actions_1 = require("../../workflow/actions");
const actions_2 = require("../actions");
const C = __importStar(require("../constants"));
exports.init = (payload) => {
    return promise_2.dispatchedAppPromise([C.WAITING_API], (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
        const { authToken, executionUuid } = yield promise_2.retryPromise(() => Endpoints_1.Endpoints.init(getState().api.serverUrl, getState().api.flowName, payload));
        dispatch(actions_2.setState({ executionUuid, authToken }));
        auth_1.setAuthCookie(executionUuid, authToken, getState().app.configuration.authorizationTimeout);
        return dispatch(exports.status());
    }));
};
exports.refresh = () => {
    return (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
        if (!getState().api.executionUuid || !getState().api.authToken) {
            throw new hub_client_translations_1.CoreError('FLOW_STATUS_CANNOT_BE_FETCHED');
        }
        const status = yield promise_2.retryPromise(() => Endpoints_1.Endpoints.status(getState().api.serverUrl, getState().api.executionUuid, getState().api.authToken));
        return dispatch(processResponse(status));
    });
};
exports.status = () => {
    return promise_2.dispatchedAppPromise([C.WAITING_API], (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
        if (!getState().api.executionUuid || !getState().api.authToken) {
            throw new hub_client_translations_1.CoreError('FLOW_STATUS_CANNOT_BE_FETCHED');
        }
        const status = yield promise_2.retryPromise(() => Endpoints_1.Endpoints.status(getState().api.serverUrl, getState().api.executionUuid, getState().api.authToken), (error) => __awaiter(void 0, void 0, void 0, function* () {
            if (error instanceof hub_client_translations_1.CoreError && error.code === 'API_REQUEST_FAILED_404') {
                dispatch(actions_1.flowReload());
                return;
            }
        }));
        return dispatch(processResponse(status));
    }));
};
exports.submit = (payload) => {
    return (dispatch, getState) => {
        return promise_2.appPromise(dispatch, [C.WAITING_API], (() => __awaiter(void 0, void 0, void 0, function* () {
            const payloadObj = yield promise_2.appPromise(dispatch, [C.WAITING_API_UPLOAD], dispatch(resolveCacheFiles(payload)));
            const submitInfo = yield promise_2.retryPromise(() => Endpoints_1.Endpoints.submit(getState().api.serverUrl, getState().api.executionUuid, getState().api.authToken, getState().api.status.uuid, payloadObj), (error) => __awaiter(void 0, void 0, void 0, function* () {
                yield promise_1.decision.baseNotValidationCatch(error);
                if (error instanceof hub_client_translations_1.CoreError && error.code === 'API_REQUEST_FAILED_404') {
                    dispatch(actions_1.flowReload());
                    return;
                }
                const status = yield promise_2.retryPromise(() => Endpoints_1.Endpoints.status(getState().api.serverUrl, getState().api.executionUuid, getState().api.authToken));
                if (status.uuid === utils_1.safe(() => getState().api.status.uuid, null)) {
                    return null;
                }
                return { response: status };
            }));
            const status = submitInfo.processing
                ? yield promise_2.retryPromise(() => Endpoints_1.Endpoints.status(getState().api.serverUrl, getState().api.executionUuid, getState().api.authToken))
                : submitInfo.response;
            return dispatch(processResponse(status));
        }))());
    };
};
exports.back = () => {
    return (dispatch, getState) => {
        const payload = getState().form.data;
        return promise_2.appPromise(dispatch, [C.WAITING_API], (() => __awaiter(void 0, void 0, void 0, function* () {
            const payloadObj = yield promise_2.appPromise(dispatch, [C.WAITING_API_UPLOAD], dispatch(resolveCacheFiles(payload)));
            const status = yield promise_2.retryPromise(() => Endpoints_1.Endpoints.flowBack(getState().api.serverUrl, getState().api.executionUuid, getState().api.authToken, getState().api.status.uuid, payloadObj));
            return dispatch(processResponse(status));
        }))());
    };
};
exports.apiFunction = (functionName, resultKey, payload) => {
    return (dispatch, getState) => {
        return promise_2.appPromise(dispatch, [C.WAITING_FUNCTION_API, resultKey], (() => __awaiter(void 0, void 0, void 0, function* () {
            const executeInfo = yield promise_2.retryPromise(() => Endpoints_1.Endpoints.functionExecute(getState().api.serverUrl, getState().api.executionUuid, getState().api.authToken, functionName, payload));
            const result = yield promise_2.retryPromise(() => Endpoints_1.Endpoints.functionResult(getState().api.serverUrl, getState().api.executionUuid, getState().api.authToken, executeInfo.uuid));
            dispatch(actions_2.setState({
                functionResults: Object.assign(Object.assign({}, getState().api.functionResults), { [resultKey]: result }),
            }));
            return result;
        }))());
    };
};
exports.getFile = (fileId) => {
    return promise_2.dispatchedAppRetryPromise([C.WAITING_API_DOWNLOAD], (_, getState) => Endpoints_1.Endpoints.getFile(getState().api.serverUrl, getState().api.authToken, fileId));
};
const processResponse = (status) => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(actions_2.setState({
            status,
            export: status.export,
            payload: status.payload,
            backEnabled: status.backEnabled,
        }));
        if (status.payload) {
            const data = yield utils_1.translateFiles(status.payload, (object) => __awaiter(void 0, void 0, void 0, function* () {
                const file = yield dispatch(exports.getFile(object.uuid));
                return utils_1.FileHolder.getHolder(file, object);
            }));
            status.payload = data;
            return status;
        }
        else {
            return status;
        }
    });
};
const resolveCacheFiles = (payload) => {
    return (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
        const keys = Object.keys(payload || {});
        const processedValues = yield Promise.all(keys.map(key => {
            if (payload[key] instanceof utils_1.FileHolder) {
                if (payload[key].originalData) {
                    return payload[key].originalData;
                }
                return promise_2.retryPromise(() => Endpoints_1.Endpoints.cacheFile(getState().api.serverUrl, payload[key].file, getState().api.authToken, p => dispatch(actions_2.setProgress(key, p))));
            }
            else if (utils_1.isArrayInstancesOf(payload[key], utils_1.FileHolder)) {
                const accumulator = payload[key].map(() => 0);
                return Promise.all(payload[key].map((file, index) => {
                    if (file.originalData) {
                        return file.originalData;
                    }
                    return promise_2.retryPromise(() => Endpoints_1.Endpoints.cacheFile(getState().api.serverUrl, file.file, getState().api.authToken, p => {
                        accumulator[index] = p;
                        dispatch(actions_2.setProgress(key, accumulator.reduce((acc, val) => acc + val) / accumulator.length));
                    }));
                }));
            }
            return payload[key];
        }));
        return processedValues.reduce((result, value, index) => {
            result[keys[index]] = value;
            return result;
        }, {});
    });
};
