"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const constants_1 = require("../app/constants");
const constants_2 = require("./constants");
const initialState = {
    serverUrl: null,
    flowName: null,
    flowIdName: null,
    flowIdRevision: null,
    authToken: null,
    executionUuid: null,
    status: null,
    export: {},
    payload: {},
    backEnabled: false,
    progress: {},
    functionResults: {},
    exportedNamespacesResults: {},
    validationErrors: [],
};
exports.default = (state = initialState, action) => {
    switch (action.type) {
        case constants_2.API_SET_STATE:
            return Object.assign(Object.assign({}, state), action.values);
        case constants_2.API_SUBMIT_START:
            return Object.assign(Object.assign({}, state), { validationErrors: [] });
        case constants_2.API_SET_VALIDATION_ERRORS:
            return Object.assign(Object.assign({}, state), { validationErrors: action.validationErrors });
        case constants_2.API_SET_FUNCTION_RESULT:
            return Object.assign(Object.assign({}, state), { functionResults: Object.assign(Object.assign({}, state.functionResults), { [action.key]: action.result }) });
        case constants_2.API_SET_EXPORTED_NAMESPACE_RESULT:
            return Object.assign(Object.assign({}, state), { exportedNamespacesResults: Object.assign(Object.assign({}, state.exportedNamespacesResults), { [action.key]: action.result }) });
        case constants_2.API_SET_PROGRESS:
            return Object.assign(Object.assign({}, state), { progress: Object.assign(Object.assign({}, state.progress), { [action.key]: action.progress }) });
        case constants_1.APP_RESOLVE_CONFIGURATION_SUCCESS:
            return Object.assign(Object.assign({}, state), { serverUrl: action.resolvedConfiguration.serverUrl.replace(/\/+$/, ''), flowName: action.resolvedConfiguration.flowName, flowIdName: action.resolvedConfiguration.flowIdName, flowIdRevision: action.resolvedConfiguration.flowIdRevision });
        default:
            return state;
    }
};
