"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.API_SEND_FILE_ACTIONS = exports.API_SEND_FILE_DONE = exports.API_SEND_FILE_ERROR = exports.API_SEND_FILE_START = exports.API_SEND_FILES_ACTIONS = exports.API_SEND_FILES_DONE = exports.API_SEND_FILES_ERROR = exports.API_SEND_FILES_START = exports.API_GET_FILE_ACTIONS = exports.API_GET_FILE_DONE = exports.API_GET_FILE_ERROR = exports.API_GET_FILE_START = exports.API_EXPORTED_NAMESPACE_ACTIONS = exports.API_EXPORTED_NAMESPACE_DONE = exports.API_EXPORTED_NAMESPACE_VALIDATION_ERROR = exports.API_EXPORTED_NAMESPACE_ERROR = exports.API_EXPORTED_NAMESPACE_START = exports.API_FUNCTION_ACTIONS = exports.API_FUNCTION_DONE = exports.API_FUNCTION_VALIDATION_ERROR = exports.API_FUNCTION_ERROR = exports.API_FUNCTION_START = exports.API_BACK_ACTIONS = exports.API_BACK_DONE = exports.API_BACK_ERROR = exports.API_BACK_START = exports.API_REINIT_ACTIONS = exports.API_REINIT_DONE = exports.API_REINIT_ERROR = exports.API_REINIT_START = exports.API_SHARABLE_TOKEN_INIT_ACTIONS = exports.API_SHARABLE_TOKEN_INIT_DONE = exports.API_SHARABLE_TOKEN_INIT_ERROR = exports.API_SHARABLE_TOKEN_INIT_START = exports.API_INIT_ACTIONS = exports.API_INIT_DONE = exports.API_INIT_ERROR = exports.API_INIT_START = exports.API_SUBMIT_ACTIONS = exports.API_SUBMIT_DONE = exports.API_SUBMIT_VALIDATION_ERROR = exports.API_SUBMIT_ERROR = exports.API_SUBMIT_START = exports.PROCESSING_API = exports.WAITING_EXPORTED_NAMESPACE_API = exports.WAITING_FUNCTION_API = exports.WAITING_API_DOWNLOAD = exports.WAITING_API_UPLOAD = exports.WAITING_API = exports.API_SET_PROGRESS = exports.API_SET_VALIDATION_ERRORS = exports.API_SET_STATE = exports.API_SET_EXPORTED_NAMESPACE_RESULT = exports.API_SET_FUNCTION_RESULT = void 0;
exports.API_SET_FUNCTION_RESULT = 'API_SET_FUNCTION_RESULT';
exports.API_SET_EXPORTED_NAMESPACE_RESULT = 'API_SET_EXPORTED_NAMESPACE_RESULT';
exports.API_SET_STATE = 'API_SET_STATE';
exports.API_SET_VALIDATION_ERRORS = 'API_SET_VALIDATION_ERRORS';
exports.API_SET_PROGRESS = 'API_SET_PROGRESS';
exports.WAITING_API = 'WAITING_API';
exports.WAITING_API_UPLOAD = 'WAITING_API_UPLOAD';
exports.WAITING_API_DOWNLOAD = 'WAITING_API_DOWNLOAD';
exports.WAITING_FUNCTION_API = 'WAITING_FUNCTION_API';
exports.WAITING_EXPORTED_NAMESPACE_API = 'WAITING_EXPORTED_NAMESPACE_API';
exports.PROCESSING_API = 'PROCESSING_API';
exports.API_SUBMIT_START = 'API_SUBMIT_START';
exports.API_SUBMIT_ERROR = 'API_SUBMIT_ERROR';
exports.API_SUBMIT_VALIDATION_ERROR = 'API_SUBMIT_VALIDATION_ERROR';
exports.API_SUBMIT_DONE = 'API_SUBMIT_DONE';
exports.API_SUBMIT_ACTIONS = {
    start: exports.API_SUBMIT_START,
    validationError: exports.API_SUBMIT_VALIDATION_ERROR,
    error: exports.API_SUBMIT_ERROR,
    done: exports.API_SUBMIT_DONE,
    tags: [exports.WAITING_API],
};
exports.API_INIT_START = 'API_INIT_START';
exports.API_INIT_ERROR = 'API_INIT_ERROR';
exports.API_INIT_DONE = 'API_INIT_DONE';
exports.API_INIT_ACTIONS = {
    start: exports.API_INIT_START,
    error: exports.API_INIT_ERROR,
    done: exports.API_INIT_DONE,
    tags: [exports.WAITING_API],
};
exports.API_SHARABLE_TOKEN_INIT_START = 'API_SHARABLE_TOKEN_INIT_START';
exports.API_SHARABLE_TOKEN_INIT_ERROR = 'API_SHARABLE_TOKEN_INIT_ERROR';
exports.API_SHARABLE_TOKEN_INIT_DONE = 'API_SHARABLE_TOKEN_INIT_DONE';
exports.API_SHARABLE_TOKEN_INIT_ACTIONS = {
    start: exports.API_SHARABLE_TOKEN_INIT_START,
    error: exports.API_SHARABLE_TOKEN_INIT_ERROR,
    done: exports.API_SHARABLE_TOKEN_INIT_DONE,
    tags: [exports.WAITING_API],
};
exports.API_REINIT_START = 'API_REINIT_START';
exports.API_REINIT_ERROR = 'API_REINIT_ERROR';
exports.API_REINIT_DONE = 'API_REINIT_DONE';
exports.API_REINIT_ACTIONS = {
    start: exports.API_REINIT_START,
    error: exports.API_REINIT_ERROR,
    done: exports.API_REINIT_DONE,
    tags: [exports.WAITING_API],
};
exports.API_BACK_START = 'API_BACK_START';
exports.API_BACK_ERROR = 'API_BACK_ERROR';
exports.API_BACK_DONE = 'API_BACK_DONE';
exports.API_BACK_ACTIONS = {
    start: exports.API_BACK_START,
    error: exports.API_BACK_ERROR,
    done: exports.API_BACK_DONE,
    tags: [exports.WAITING_API],
};
exports.API_FUNCTION_START = 'API_FUNCTION_START';
exports.API_FUNCTION_ERROR = 'API_FUNCTION_ERROR';
exports.API_FUNCTION_VALIDATION_ERROR = 'API_FUNCTION_VALIDATION_ERROR';
exports.API_FUNCTION_DONE = 'API_FUNCTION_DONE';
exports.API_FUNCTION_ACTIONS = {
    start: exports.API_FUNCTION_START,
    validationError: exports.API_FUNCTION_VALIDATION_ERROR,
    error: exports.API_FUNCTION_ERROR,
    done: exports.API_FUNCTION_DONE,
    tags: [exports.WAITING_FUNCTION_API],
};
exports.API_EXPORTED_NAMESPACE_START = 'API_EXPORTED_NAMESPACE_START';
exports.API_EXPORTED_NAMESPACE_ERROR = 'API_EXPORTED_NAMESPACE_ERROR';
exports.API_EXPORTED_NAMESPACE_VALIDATION_ERROR = 'API_EXPORTED_NAMESPACE_VALIDATION_ERROR';
exports.API_EXPORTED_NAMESPACE_DONE = 'API_EXPORTED_NAMESPACE_DONE';
exports.API_EXPORTED_NAMESPACE_ACTIONS = {
    start: exports.API_EXPORTED_NAMESPACE_START,
    validationError: exports.API_EXPORTED_NAMESPACE_VALIDATION_ERROR,
    error: exports.API_EXPORTED_NAMESPACE_ERROR,
    done: exports.API_EXPORTED_NAMESPACE_DONE,
    tags: [exports.WAITING_EXPORTED_NAMESPACE_API],
};
exports.API_GET_FILE_START = 'API_GET_FILE_START';
exports.API_GET_FILE_ERROR = 'API_GET_FILE_ERROR';
exports.API_GET_FILE_DONE = 'API_GET_FILE_DONE';
exports.API_GET_FILE_ACTIONS = {
    start: exports.API_GET_FILE_START,
    error: exports.API_GET_FILE_ERROR,
    done: exports.API_GET_FILE_DONE,
    tags: [exports.WAITING_API_DOWNLOAD],
};
exports.API_SEND_FILES_START = 'API_SEND_FILES_START';
exports.API_SEND_FILES_ERROR = 'API_SEND_FILES_ERROR';
exports.API_SEND_FILES_DONE = 'API_SEND_FILES_DONE';
exports.API_SEND_FILES_ACTIONS = {
    start: exports.API_SEND_FILES_START,
    error: exports.API_SEND_FILES_ERROR,
    done: exports.API_SEND_FILES_DONE,
    tags: [exports.WAITING_API_UPLOAD],
};
exports.API_SEND_FILE_START = 'API_SEND_FILE_START';
exports.API_SEND_FILE_ERROR = 'API_SEND_FILE_ERROR';
exports.API_SEND_FILE_DONE = 'API_SEND_FILE_DONE';
exports.API_SEND_FILE_ACTIONS = {
    start: exports.API_SEND_FILE_START,
    error: exports.API_SEND_FILE_ERROR,
    done: exports.API_SEND_FILE_DONE,
    tags: [exports.WAITING_API_UPLOAD],
};
