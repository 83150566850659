"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const utils_1 = require("@zenoo/hub-client-utils/utils");
const emotion_1 = require("emotion");
const react_1 = __importStar(require("react"));
const hooks_1 = require("../utils/hooks");
const stylePageActive = emotion_1.css `
  display: block;
`;
const stylePageInactive = emotion_1.css `
  display: none;
`;
const CSS_CLASS_TRANSITION_CONTAINER = 'css-transition-container';
const CSS_CLASS_TRANSITION_CONTAINER_INACTIVE = 'css-transition-container-inactive';
const CSS_CLASS_TRANSITION_CONTAINER_ACTIVE = 'css-transition-container-active';
const CSS_CLASS_TRANSITION_CONTAINER_ANIMATION_FORMWARD = 'css-transition-container-animation-forward';
const CSS_CLASS_TRANSITION_CONTAINER_ANIMATION_BACKWARD = 'css-transition-container-animation-backward';
const CSS_CLASS_TRANSITION_CONTAINER_ANIMATION_STATIC = 'css-transition-container-animation-static';
const CSS_CLASS_TRANSITIONS_WRAPPER = 'css-transitions-wrapper';
const TransitionContainer = ({ children }) => {
    const [animatedTransition, setAnimatedTransition] = react_1.useState(false);
    const [topOffset, setTopOffset] = react_1.useState(0);
    const { operating, pageDirection, currentPage } = hooks_1.useAppStore(store => store.workflow);
    const innerHtmlBackup = react_1.useRef(null);
    const containerRef = react_1.useRef(null);
    utils_1.useComponentWillReceiveProps(() => {
        if (currentPage) {
            setAnimatedTransition(true);
            setTopOffset(containerRef.current ? containerRef.current.scrollTop : 0);
            if (containerRef.current) {
                containerRef.current.scrollTop = 0;
                document.body.scrollTop = 0;
                window.scrollTo(0, 0);
            }
        }
    }, [currentPage]);
    const onContainerRefGet = (element) => {
        if (!element) {
            return;
        }
        containerRef.current = element;
        element.addEventListener('animationend', () => {
            setAnimatedTransition(false);
        });
    };
    const onActiveRefGet = (element) => {
        if (!operating && element) {
            innerHtmlBackup.current = element.innerHTML;
        }
    };
    const animClassName = pageDirection === 1 || !utils_1.isSet(pageDirection)
        ? CSS_CLASS_TRANSITION_CONTAINER_ANIMATION_FORMWARD
        : pageDirection === -1
            ? CSS_CLASS_TRANSITION_CONTAINER_ANIMATION_BACKWARD
            : CSS_CLASS_TRANSITION_CONTAINER_ANIMATION_STATIC;
    return (react_1.default.createElement("div", { className: emotion_1.cx(CSS_CLASS_TRANSITIONS_WRAPPER), ref: onContainerRefGet },
        innerHtmlBackup.current && animatedTransition ? (react_1.default.createElement("div", { className: emotion_1.cx(stylePageInactive, CSS_CLASS_TRANSITION_CONTAINER, CSS_CLASS_TRANSITION_CONTAINER_INACTIVE, {
                [animClassName]: animatedTransition,
            }), style: {
                top: -topOffset + 'px',
            }, dangerouslySetInnerHTML: {
                __html: innerHtmlBackup.current,
            } })) : null,
        react_1.default.createElement("div", { className: emotion_1.cx(stylePageActive, CSS_CLASS_TRANSITION_CONTAINER, CSS_CLASS_TRANSITION_CONTAINER_ACTIVE, {
                [animClassName]: animatedTransition,
            }), ref: onActiveRefGet }, children)));
};
exports.default = TransitionContainer;
