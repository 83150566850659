"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const constants_1 = require("./constants");
const initialState = {
    currentPage: constants_1.INDEX_PAGE,
    currentResolvedPage: {},
    currentPageUuid: null,
    pageParams: {},
    pageDirection: 1,
    operating: false,
    error: undefined,
    initialized: false,
    authCookieInitialized: false,
    currentPageContext: {},
};
exports.default = (state = initialState, action) => {
    switch (action.type) {
        case constants_1.FLOW_CHANGE_PAGE_SUCCESS:
            return Object.assign(Object.assign({}, state), { currentPage: action.pageName, currentPageUuid: action.uuid, pageDirection: action.direction, pageParams: action.params, currentResolvedPage: {}, currentPageContext: {} });
        case constants_1.FLOW_RESOLVE_PAGE_SUCCESS:
            return Object.assign(Object.assign({}, state), { currentResolvedPage: action.resolvedPage, currentPageContext: action.expressionContext });
        case constants_1.FLOW_BACK_START:
        case constants_1.FLOW_CONTINUE_START:
        case constants_1.FLOW_START_START:
        case constants_1.FLOW_FUNCTION_START:
        case constants_1.FLOW_SUBMIT_START:
        case constants_1.FLOW_SHARABLE_TOKEN_HEADLESS_START:
            return Object.assign(Object.assign({}, state), { operating: true });
        case constants_1.FLOW_BACK_ERROR:
        case constants_1.FLOW_CONTINUE_ERROR:
        case constants_1.FLOW_START_ERROR:
        case constants_1.FLOW_FUNCTION_ERROR:
        case constants_1.FLOW_SUBMIT_ERROR:
        case constants_1.FLOW_SHARABLE_TOKEN_HEADLESS_ERROR:
            return Object.assign(Object.assign({}, state), { operating: false, error: action.error });
        case constants_1.FLOW_BACK_SUCCESS:
        case constants_1.FLOW_START_SUCCESS:
        case constants_1.FLOW_CONTINUE_SUCCESS:
        case constants_1.FLOW_FUNCTION_SUCCESS:
        case constants_1.FLOW_SUBMIT_SUCCESS:
        case constants_1.FLOW_SHARABLE_TOKEN_HEADLESS_SUCCESS:
            return Object.assign(Object.assign({}, state), { operating: false, initialized: true });
        case constants_1.FLOW_AUTH_COOKIE_INITIALIZED:
            return Object.assign(Object.assign({}, state), { authCookieInitialized: true });
        default:
            return state;
    }
};
