"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.APP_PAGE_CLEAR_ALL_TIMEOUTS_INTERVALS = exports.APP_PAGE_CLEAR_INTERVAL = exports.APP_PAGE_SET_INTERVAL = exports.APP_PAGE_CLEAR_TIMEOUT = exports.APP_PAGE_SET_TIMEOUT = exports.APP_STORAGE_RESET = exports.APP_STORAGE_SET = exports.APP_CHANGE_LOCALE = exports.APP_EXPRESSIONS_CONFIG_INITIALIZED = exports.APP_TRANSLATIONS_INITIALIZED = exports.APP_RESOLVE_CONFIGURATION_ERROR = exports.APP_RESOLVE_CONFIGURATION_SUCCESS = exports.APP_RESOLVE_CONFIGURATION_START = exports.APP_POP_WAITING_FOR = exports.APP_PUSH_WAITING_FOR = exports.APP_LOGOUT_SUCCESS = exports.APP_LOGOUT_ERROR = exports.APP_LOGOUT_START = exports.APP_INITIALIZE_ERROR = exports.APP_INITIALIZE_SUCCESS = exports.APP_INITIALIZE_START = void 0;
exports.APP_INITIALIZE_START = 'APP_INITIALIZE_START';
exports.APP_INITIALIZE_SUCCESS = 'APP_INITIALIZE_SUCCESS';
exports.APP_INITIALIZE_ERROR = 'APP_INITIALIZE_ERROR';
exports.APP_LOGOUT_START = 'APP_LOGOUT_START';
exports.APP_LOGOUT_ERROR = 'APP_LOGOUT_ERROR';
exports.APP_LOGOUT_SUCCESS = 'APP_LOGOUT_DONE';
exports.APP_PUSH_WAITING_FOR = 'APP_PUSH_WAITING_FOR';
exports.APP_POP_WAITING_FOR = 'APP_POP_WAITING_FOR';
exports.APP_RESOLVE_CONFIGURATION_START = 'APP_RESOLVE_CONFIGURATION_START';
exports.APP_RESOLVE_CONFIGURATION_SUCCESS = 'APP_RESOLVE_CONFIGURATION_SUCCESS';
exports.APP_RESOLVE_CONFIGURATION_ERROR = 'APP_RESOLVE_CONFIGURATION_ERROR';
exports.APP_TRANSLATIONS_INITIALIZED = 'APP_TRANSLATIONS_INITIALIZED';
exports.APP_EXPRESSIONS_CONFIG_INITIALIZED = 'APP_EXPRESSIONS_CONFIG_INITIALIZED';
exports.APP_CHANGE_LOCALE = 'APP_CHANGE_LOCALE';
exports.APP_STORAGE_SET = 'APP_STORAGE_SET';
exports.APP_STORAGE_RESET = 'APP_STORAGE_RESET';
exports.APP_PAGE_SET_TIMEOUT = 'APP_PAGE_SET_TIMEOUT';
exports.APP_PAGE_CLEAR_TIMEOUT = 'APP_PAGE_CLEAR_TIMEOUT';
exports.APP_PAGE_SET_INTERVAL = 'APP_PAGE_SET_INTERVAL';
exports.APP_PAGE_CLEAR_INTERVAL = 'APP_PAGE_CLEAR_INTERVAL';
exports.APP_PAGE_CLEAR_ALL_TIMEOUTS_INTERVALS = 'APP_PAGE_CLEAR_ALL_TIMEOUTS_INTERVALS';
