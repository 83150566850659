"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.setParrentLocalStorageData = exports.getParrentLocalStorageData = exports.setParentFrameHeight = exports.setParrentAuthData = exports.getParrentAuthData = exports.setParrentData = exports.getParrentData = exports.sendParentAction = exports.checkCookiesAvaliable = exports.getIsInIframe = void 0;
const Cookies = __importStar(require("js-cookie"));
const uuid_1 = require("uuid");
function getIsInIframe() {
    try {
        return window.self !== window.top;
    }
    catch (e) {
        return true;
    }
}
exports.getIsInIframe = getIsInIframe;
function checkCookiesAvaliable() {
    const value = uuid_1.v4();
    Cookies.set('app-check-test', value, { sameSite: 'none', secure: true, path: window.location.pathname });
    const result = Cookies.get('app-check-test');
    Cookies.remove('app-check-test');
    return result === value;
}
exports.checkCookiesAvaliable = checkCookiesAvaliable;
function sendParentAction(action, data) {
    return __awaiter(this, void 0, void 0, function* () {
        return new Promise((resolve) => {
            const id = uuid_1.v4();
            const cb = e => {
                if (e.data.id === id) {
                    if (e.data.error) {
                        throw new Error(e.data.error);
                    }
                    window.removeEventListener('message', cb);
                    resolve(e.data.data);
                }
            };
            window.addEventListener('message', cb);
            window.parent.postMessage({ action, data, id }, '*');
        });
    });
}
exports.sendParentAction = sendParentAction;
function getParrentData(name) {
    return __awaiter(this, void 0, void 0, function* () {
        return sendParentAction('get-value', { name });
    });
}
exports.getParrentData = getParrentData;
function setParrentData(name, value) {
    return __awaiter(this, void 0, void 0, function* () {
        return sendParentAction('set-value', { name, value });
    });
}
exports.setParrentData = setParrentData;
function getParrentAuthData() {
    return __awaiter(this, void 0, void 0, function* () {
        return sendParentAction('get-auth-cookie', {});
    });
}
exports.getParrentAuthData = getParrentAuthData;
function setParrentAuthData(value, expires) {
    return __awaiter(this, void 0, void 0, function* () {
        return sendParentAction('set-auth-cookie', { value, expires });
    });
}
exports.setParrentAuthData = setParrentAuthData;
function setParentFrameHeight() {
    return __awaiter(this, void 0, void 0, function* () {
        return sendParentAction('set-frame-height', { height: document.querySelector('body').clientHeight });
    });
}
exports.setParentFrameHeight = setParentFrameHeight;
function getParrentLocalStorageData(key, defaultValue = undefined) {
    return __awaiter(this, void 0, void 0, function* () {
        return sendParentAction('get-localstorage-value', { key, defaultValue });
    });
}
exports.getParrentLocalStorageData = getParrentLocalStorageData;
function setParrentLocalStorageData(key, value) {
    return __awaiter(this, void 0, void 0, function* () {
        return sendParentAction('set-localstorage-value', { key, value });
    });
}
exports.setParrentLocalStorageData = setParrentLocalStorageData;
