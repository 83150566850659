"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialState = void 0;
const utils_1 = require("@zenoo/hub-client-utils/utils");
const constants_1 = require("./constants");
exports.initialState = {
    targetId: process.env.TARGET_ID,
    initialized: false,
    initializing: false,
    loggingOut: false,
    loggedOut: false,
    waitingFor: [],
    locale: null,
    favicon: null,
    configuration: undefined,
    unresolvedConfiguration: undefined,
    pageStorage: {},
    timeouts: [],
    intervals: [],
};
exports.default = (state = exports.initialState, action) => {
    switch (action.type) {
        case constants_1.APP_INITIALIZE_START:
            return Object.assign(Object.assign({}, state), { initializing: true });
        case constants_1.APP_INITIALIZE_ERROR:
            return Object.assign(Object.assign({}, state), { initializing: false });
        case constants_1.APP_INITIALIZE_SUCCESS:
            return Object.assign(Object.assign({}, state), { initializing: false, initialized: true });
        case constants_1.APP_LOGOUT_START:
            return Object.assign(Object.assign({}, state), { loggingOut: true });
        case constants_1.APP_LOGOUT_ERROR:
            return Object.assign(Object.assign({}, state), { loggingOut: false });
        case constants_1.APP_RESOLVE_CONFIGURATION_SUCCESS:
            return Object.assign(Object.assign({}, state), { configuration: action.resolvedConfiguration });
        case constants_1.APP_LOGOUT_SUCCESS:
            return Object.assign(Object.assign({}, state), { loggedOut: true, loggingOut: false });
        case constants_1.APP_PUSH_WAITING_FOR: {
            const newWaitingFor = [...state.waitingFor, action.tags];
            return Object.assign(Object.assign({}, state), { waitingFor: newWaitingFor });
        }
        case constants_1.APP_POP_WAITING_FOR: {
            const newWaitingFor = state.waitingFor.filter(item => {
                return !action.tags.some(i => item.indexOf(i) >= 0);
            });
            return Object.assign(Object.assign({}, state), { waitingFor: newWaitingFor });
        }
        case constants_1.APP_CHANGE_LOCALE:
            return Object.assign(Object.assign({}, state), { locale: action.locale });
        case constants_1.APP_TRANSLATIONS_INITIALIZED:
            return Object.assign(Object.assign({}, state), { locale: action.defaultLocale });
        case constants_1.APP_STORAGE_SET: {
            const pageStorage = Object.assign({}, state.pageStorage);
            utils_1.setByExpression(pageStorage, action.key, action.value);
            return Object.assign(Object.assign({}, state), { pageStorage });
        }
        case constants_1.APP_STORAGE_RESET:
            return Object.assign(Object.assign({}, state), { pageStorage: {} });
        case constants_1.APP_PAGE_SET_TIMEOUT:
            return Object.assign(Object.assign({}, state), { timeouts: [...state.timeouts, action.handler] });
        case constants_1.APP_PAGE_CLEAR_TIMEOUT:
            return Object.assign(Object.assign({}, state), { timeouts: [...state.timeouts.filter(i => i !== action.handler)] });
        case constants_1.APP_PAGE_SET_INTERVAL:
            return Object.assign(Object.assign({}, state), { intervals: [...state.intervals, action.handler] });
        case constants_1.APP_PAGE_CLEAR_INTERVAL:
            return Object.assign(Object.assign({}, state), { intervals: [...state.intervals.filter(i => i !== action.handler)] });
        case constants_1.APP_PAGE_CLEAR_ALL_TIMEOUTS_INTERVALS:
            return Object.assign(Object.assign({}, state), { intervals: [], timeouts: [] });
        default:
            return state;
    }
};
