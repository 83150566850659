"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.prepareFaviconUrl = exports.getFavicon = exports.EMPTY_FAVICON = exports.VALID_EXTENSIONS = void 0;
const utils_1 = require("@zenoo/hub-client-utils/utils");
exports.VALID_EXTENSIONS = { gif: 'gif', ico: 'x-icon', png: 'png' };
exports.EMPTY_FAVICON = { type: 'image/x-icon', href: 'data:;base64,iVBORw0KGgo=' };
function getFavicon(favicon) {
    if (favicon) {
        const fileExtension = utils_1.getFileExtension(favicon);
        if (fileExtension) {
            const foundedExtension = Object.keys(exports.VALID_EXTENSIONS).find(ext => ext === fileExtension.toLowerCase());
            if (foundedExtension) {
                return {
                    type: `image/${exports.VALID_EXTENSIONS[foundedExtension]}`,
                    href: favicon,
                };
            }
        }
    }
    return exports.EMPTY_FAVICON;
}
exports.getFavicon = getFavicon;
function prepareFaviconUrl(url) {
    const assetsPosition = url.indexOf('assets/');
    if (assetsPosition === -1) {
        return url;
    }
    return `./${url.substring(assetsPosition)}`;
}
exports.prepareFaviconUrl = prepareFaviconUrl;
