"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendFiles = exports.sendFile = exports.sendRawFile = exports.getFile = exports.apiGetExportedNamespace = exports.apiFunction = exports.back = exports.submit = exports.refresh = exports.reInit = exports.sharableInit = exports.initLegacy = exports.init = void 0;
const hub_client_translations_1 = require("@zenoo/hub-client-translations");
const utils_1 = require("@zenoo/hub-client-utils/utils");
const endpoints = __importStar(require("../../../../api/v1/actions"));
const Errors_1 = require("../../../../utils/Errors");
const promise_1 = require("../../../../utils/promise");
const promise_2 = require("../../../../utils/promise");
const actions_1 = require("../actions");
const C = __importStar(require("../constants"));
const utils_2 = require("./utils");
exports.init = (payload) => {
    return (dispatch, getState) => dispatch(utils_2.apiPostWithResultSave(C.API_INIT_ACTIONS, () => {
        const componentFlowId = getState().api.flowIdName
            ? Object.assign({ name: getState().api.flowIdName }, (getState().api.flowIdRevision ? { revision: getState().api.flowIdRevision } : {})) : null;
        return endpoints.init(getState().api.serverUrl, getState().api.flowName, componentFlowId, payload);
    }));
};
exports.initLegacy = (payload) => {
    return (dispatch, getState) => dispatch(utils_2.apiPostWithResultSave(C.API_INIT_ACTIONS, () => {
        const componentFlowId = getState().api.flowIdName
            ? Object.assign({ name: getState().api.flowIdName }, (getState().api.flowIdRevision ? { revision: getState().api.flowIdRevision } : {})) : null;
        return endpoints.initLegacy(getState().api.serverUrl, getState().api.flowName, componentFlowId, payload);
    }));
};
exports.sharableInit = (token, payload) => {
    return (dispatch, getState) => dispatch(utils_2.apiPostWithResultSave(C.API_SHARABLE_TOKEN_INIT_ACTIONS, () => endpoints.sharableInit(getState().api.serverUrl, token, payload), error => {
        if (error instanceof Errors_1.UnexpectedStatusError) {
            if (error.response.status === utils_1.HTTP_STATUS.NOT_FOUND) {
                throw new hub_client_translations_1.CoreError('API_INVALID_SHARABLE_TOKEN');
            }
            if (error.response.status === utils_1.HTTP_STATUS.GONE) {
                throw new hub_client_translations_1.CoreError('API_EXPIRED_SHARABLE_TOKEN');
            }
        }
        if (error instanceof hub_client_translations_1.CoreError) {
            dispatch(actions_1.callStoreAction(C.API_SHARABLE_TOKEN_INIT_ACTIONS.error));
            throw error;
        }
        return null;
    }));
};
exports.reInit = () => {
    return (dispatch, getState) => {
        if (!getState().api.executionUuid || !getState().api.authToken) {
            throw new hub_client_translations_1.CoreError('FLOW_STATUS_CANNOT_BE_FETCHED');
        }
        return dispatch(utils_2.apiGetWithSave(C.API_REINIT_ACTIONS, () => endpoints.status(getState().api.serverUrl, getState().api.executionUuid, getState().api.authToken)));
    };
};
exports.refresh = () => {
    return (dispatch, getState) => {
        if (!getState().api.executionUuid || !getState().api.authToken) {
            throw new hub_client_translations_1.CoreError('FLOW_STATUS_CANNOT_BE_FETCHED');
        }
        return dispatch(utils_2.apiGetWithSave(C.API_REINIT_ACTIONS, () => endpoints.status(getState().api.serverUrl, getState().api.executionUuid, getState().api.authToken), null, true));
    };
};
exports.submit = (payload) => (dispatch, getState) => dispatch(utils_2.apiPostWithResultSave(C.API_SUBMIT_ACTIONS, () => __awaiter(void 0, void 0, void 0, function* () {
    const processedPayload = yield dispatch(utils_2.processFiles(payload));
    return endpoints.submit(getState().api.serverUrl, getState().api.executionUuid, getState().api.authToken, getState().api.status.uuid, processedPayload);
}), error => dispatch(submitError(error))));
exports.back = () => (dispatch, getState) => dispatch(utils_2.apiPostWithResultSave(C.API_BACK_ACTIONS, () => endpoints.back(getState().api.serverUrl, getState().api.executionUuid, getState().api.authToken, getState().api.status.uuid, getState().form.data), null));
exports.apiFunction = (functionName, functionKey, payload) => {
    const functionActions = Object.assign({}, C.API_FUNCTION_ACTIONS);
    functionActions.tags = [...C.API_FUNCTION_ACTIONS.tags, functionKey];
    return (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
        const resultResponse = yield dispatch(utils_2.apiPostWithResult(functionActions, () => endpoints.execute(getState().api.serverUrl, getState().api.executionUuid, getState().api.authToken, getState().api.status.uuid, functionName, payload), undefined, true));
        return dispatch(actions_1.setFunctionResult(functionKey, resultResponse));
    });
};
exports.apiGetExportedNamespace = (namespaceName, namespaceKey) => {
    const functionActions = Object.assign({}, C.API_EXPORTED_NAMESPACE_ACTIONS);
    functionActions.tags = [...C.API_EXPORTED_NAMESPACE_ACTIONS.tags, namespaceKey];
    return (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
        const resultResponse = yield dispatch(utils_2.apiPost(functionActions, () => endpoints.exportedNamespace(getState().api.serverUrl, getState().api.executionUuid, getState().api.authToken, namespaceName)));
        return dispatch(actions_1.setExportedNamespaceResult(namespaceKey, resultResponse));
    });
};
exports.getFile = (fileId) => {
    return (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(actions_1.callStoreAction(C.API_GET_FILE_ACTIONS.start, { fileId }));
        const blobResponse = yield dispatch(utils_2.retryApiCall(C.API_GET_FILE_ACTIONS.tags, () => endpoints.getFile(getState().api.serverUrl, getState().api.authToken, fileId), error => {
            dispatch(actions_1.callStoreAction(C.API_GET_FILE_ACTIONS.error));
            throw error;
        }));
        dispatch(actions_1.callStoreAction(C.API_GET_FILE_ACTIONS.done, { fileId }));
        return blobResponse;
    });
};
exports.sendRawFile = (file) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const holder = yield utils_1.FileHolder.getHolder(file);
    return dispatch(exports.sendFile(holder));
});
exports.sendFile = (fileHolder, progressFunc, callStoreActions = true) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    if (fileHolder.originalData) {
        return fileHolder.originalData;
    }
    callStoreActions && dispatch(actions_1.callStoreAction(C.API_SEND_FILE_ACTIONS.start));
    const response = yield dispatch(utils_2.retryApiCall(callStoreActions ? C.API_SEND_FILE_ACTIONS.tags : [], () => endpoints.sendFile(getState().api.serverUrl, getState().api.authToken, fileHolder.file, progressFunc), error => {
        callStoreActions && dispatch(actions_1.callStoreAction(C.API_SEND_FILE_ACTIONS.error));
        throw error;
    }));
    callStoreActions && dispatch(actions_1.callStoreAction(C.API_SEND_FILE_ACTIONS.done));
    return response;
});
exports.sendFiles = (fileHolders, progressKey) => {
    return (dispatch) => {
        if (fileHolders.every(fileHolder => fileHolder.originalData)) {
            return fileHolders.map(fileHolder => fileHolder.originalData);
        }
        const accumulator = fileHolders.map(() => 0);
        const retFiles = [];
        dispatch(actions_1.callStoreAction(C.API_SEND_FILES_ACTIONS.start));
        const promise = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                yield Promise.all(Object.keys(fileHolders).map((fileKey) => __awaiter(void 0, void 0, void 0, function* () {
                    if (fileHolders[fileKey].originalData) {
                        retFiles[fileKey] = fileHolders[fileKey].originalData;
                        return;
                    }
                    const response = yield dispatch(exports.sendFile(fileHolders[fileKey], progress => {
                        accumulator[fileKey] = progress;
                        dispatch(actions_1.setProgress(progressKey, accumulator.reduce((acc, val) => acc + val) / accumulator.length));
                    }, false));
                    retFiles[fileKey] = response;
                })));
                dispatch(actions_1.callStoreAction(C.API_SEND_FILES_ACTIONS.done, { progressKey }));
                return retFiles;
            }
            catch (error) {
                dispatch(actions_1.callStoreAction(C.API_SEND_FILES_ACTIONS.error));
                throw error;
            }
        });
        return dispatch(utils_2.apiCall(C.API_SEND_FILES_ACTIONS.tags, promise()));
    };
};
const submitError = error => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(actions_1.callStoreAction(C.API_SUBMIT_ERROR, error.message));
    promise_2.decision.baseNotValidationCatch(error);
    const statusResponse = yield promise_1.retryPromise(() => endpoints.status(getState().api.serverUrl, getState().api.executionUuid, getState().api.authToken));
    const resultResponse = yield dispatch(utils_2.processApiGet(statusResponse, C.API_SUBMIT_ACTIONS));
    if (resultResponse.uuid === utils_1.safe(() => getState().api.status.uuid, null)) {
        return null;
    }
    return { response: resultResponse };
});
