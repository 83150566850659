"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiResponseError = void 0;
const hub_client_translations_1 = require("@zenoo/hub-client-translations");
class ApiResponseError extends Error {
    constructor(message) {
        super(hub_client_translations_1.translate('API_RESPONSE_ERROR', { message }));
    }
}
exports.ApiResponseError = ApiResponseError;
