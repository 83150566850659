"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.customEvent = exports.setPeople = exports.setParams = exports.changePage = exports.authorization = exports.init = void 0;
const react_hotjar_1 = require("react-hotjar");
const analytics_1 = require("../../../../constants/analytics");
exports.init = (config) => {
    react_hotjar_1.hotjar.initialize(config.hjid, config.hjsv);
};
exports.authorization = (_token) => {
    return;
};
exports.changePage = (pageName, _params) => {
    ;
    window.hj(analytics_1.HOTJAR_STATE_CHANGE, '#' + pageName);
};
exports.setParams = (_params) => {
    return;
};
exports.setPeople = (_params) => {
    return;
};
exports.customEvent = (_eventName, _params) => {
    return;
};
