"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FORM_SUBMIT_ACTION_SUCCESS = exports.FORM_SUBMIT_ACTION_ERROR = exports.FORM_SUBMIT_ACTION_START = exports.FORM_DATA_VALIDATE_SUCCESS = exports.FORM_DATA_VALIDATE_ERROR = exports.FORM_DATA_VALIDATE_VALIDATION_ERROR = exports.FORM_DATA_VALIDATE_START = exports.FORM_VALIDATION_COMPILE_SCHEMA_ERROR = exports.FORM_VALIDATION_COMPILE_SCHEMA_SUCCESS = exports.FORM_VALIDATION_COMPILE_SCHEMA_START = exports.FORM_VALIDATION_INITIALIZED = exports.FORM_TAGS_RESET = exports.FORM_TAGS_REMOVE = exports.FORM_TAGS_ADD = exports.FORM_ERROR_SET = exports.FORM_VISITED_SET = exports.FORM_CLEAR_ERRORS = exports.FORM_DATA_VALUE_SET = exports.FORM_DATA_RESET = exports.FORM_DATA_SET = void 0;
exports.FORM_DATA_SET = 'FORM_DATA_SET';
exports.FORM_DATA_RESET = 'FORM_DATA_RESET';
exports.FORM_DATA_VALUE_SET = 'FORM_DATA_VALUE_SET';
exports.FORM_CLEAR_ERRORS = 'FORM_CLEAR_ERRORS';
exports.FORM_VISITED_SET = 'FORM_VISITED_SET';
exports.FORM_ERROR_SET = 'FORM_ERROR_SET';
exports.FORM_TAGS_ADD = 'FORM_TAGS_ADD';
exports.FORM_TAGS_REMOVE = 'FORM_TAGS_REMOVE';
exports.FORM_TAGS_RESET = 'FORM_TAGS_RESET';
exports.FORM_VALIDATION_INITIALIZED = 'FORM_VALIDATION_INITIALIZED';
exports.FORM_VALIDATION_COMPILE_SCHEMA_START = 'FORM_VALIDATION_COMPILE_SCHEMA_START';
exports.FORM_VALIDATION_COMPILE_SCHEMA_SUCCESS = 'FORM_VALIDATION_COMPILE_SCHEMA_SUCCESS';
exports.FORM_VALIDATION_COMPILE_SCHEMA_ERROR = 'FORM_VALIDATION_COMPILE_SCHEMA_ERROR';
exports.FORM_DATA_VALIDATE_START = 'FORM_DATA_VALIDATE_START';
exports.FORM_DATA_VALIDATE_VALIDATION_ERROR = 'FORM_DATA_VALIDATE_VALIDATION_ERROR';
exports.FORM_DATA_VALIDATE_ERROR = 'FORM_DATA_VALIDATE_ERROR';
exports.FORM_DATA_VALIDATE_SUCCESS = 'FORM_DATA_VALIDATE_SUCCESS';
exports.FORM_SUBMIT_ACTION_START = 'FORM_SUBMIT_ACTION_START';
exports.FORM_SUBMIT_ACTION_ERROR = 'FORM_SUBMIT_ACTION_ERROR';
exports.FORM_SUBMIT_ACTION_SUCCESS = 'FORM_SUBMIT_ACTION_SUCCESS';
