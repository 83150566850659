"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.dispatchedAppPromise = exports.appPromise = exports.dispatchedAppRetryPromise = exports.decision = exports.appRetryPromise = exports.retryPromise = void 0;
const utils_1 = require("@zenoo/hub-client-utils/utils");
const actions_1 = require("../store/modules/app/actions");
const Errors_1 = require("./Errors");
function retryExecute(promiseConstructor, backupFunction = () => __awaiter(this, void 0, void 0, function* () { return true; }), attempts = 3, attemptsDelay = 1000) {
    let i = attempts;
    const again = () => __awaiter(this, void 0, void 0, function* () {
        i--;
        return promiseConstructor().catch((error) => __awaiter(this, void 0, void 0, function* () {
            if (i > 0) {
                const result = yield backupFunction(error);
                if (!result) {
                    return asyncDelay(attemptsDelay).then(() => again());
                }
                return result;
            }
            throw error;
        }));
    });
    return again();
}
const asyncDelay = (timeMs) => __awaiter(void 0, void 0, void 0, function* () {
    if (timeMs === 0) {
        return;
    }
    return new Promise((resolve) => {
        setTimeout(resolve, timeMs);
    });
});
function retryPromise(promiseConstructor, backupFunction = exports.decision.baseNotValidationCatch) {
    return retryExecute(promiseConstructor, backupFunction);
}
exports.retryPromise = retryPromise;
function appRetryPromise(dispatch, tags, promise, backupFunction = exports.decision.baseNotValidationCatch) {
    return appPromise(dispatch, tags, retryPromise(promise, backupFunction));
}
exports.appRetryPromise = appRetryPromise;
exports.decision = {
    baseNotValidationCatch: (error) => __awaiter(void 0, void 0, void 0, function* () {
        if (error instanceof Errors_1.UnexpectedStatusError &&
            (error.response.status === utils_1.HTTP_STATUS.UNPROCESSABLE_ENTITY || error.response.status === utils_1.HTTP_STATUS.UNAUTHORIZED)) {
            throw error;
        }
        return null;
    }),
};
function dispatchedAppRetryPromise(tags, promise, backupFunction = exports.decision.baseNotValidationCatch) {
    return (dispatch, getState) => {
        return appRetryPromise(dispatch, tags, () => promise(dispatch, getState), error => backupFunction(error, dispatch, getState));
    };
}
exports.dispatchedAppRetryPromise = dispatchedAppRetryPromise;
function appPromise(dispatch, tags, promise) {
    const specId = dispatch(actions_1.appPushWaitingFor(tags));
    return promise.then(value => {
        dispatch(actions_1.appPopWaitingFor(specId));
        return value;
    }, (err) => {
        dispatch(actions_1.appPopWaitingFor(specId));
        throw err;
    });
}
exports.appPromise = appPromise;
function dispatchedAppPromise(tags, promise) {
    return (dispatch, getState) => {
        return appPromise(dispatch, tags, promise(dispatch, getState));
    };
}
exports.dispatchedAppPromise = dispatchedAppPromise;
