"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialState = void 0;
const constants_1 = require("./constants");
exports.initialState = {
    initializedProviders: [],
    params: {},
};
exports.default = (state = exports.initialState, action) => {
    switch (action.type) {
        case constants_1.ANALYTICS_INIT_SUCCESS: {
            return Object.assign(Object.assign({}, state), { initializedProviders: action.providers });
        }
        case constants_1.ANALYTICS_RESOLVE_PARAMS: {
            return Object.assign(Object.assign({}, state), { params: action.params });
        }
        default:
            return state;
    }
};
