"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.apiGetFile = exports.apiGetExportedNamespace = exports.apiFunction = exports.apiBack = exports.apiSubmit = exports.apiRefresh = exports.apiStatus = exports.apiSharableInit = exports.apiInit = exports.callStoreAction = exports.setProgress = exports.setValidationErrors = exports.setExportedNamespaceResult = exports.setFunctionResult = exports.setState = void 0;
const constants_1 = require("./constants");
const v0 = __importStar(require("./v0/actions"));
const v1 = __importStar(require("./v1/actions"));
exports.setState = (values) => {
    return {
        type: constants_1.API_SET_STATE,
        values,
    };
};
exports.setFunctionResult = (functionKey, result) => {
    return {
        type: constants_1.API_SET_FUNCTION_RESULT,
        key: functionKey,
        result,
    };
};
exports.setExportedNamespaceResult = (namespaceKey, result) => {
    return {
        type: constants_1.API_SET_EXPORTED_NAMESPACE_RESULT,
        key: namespaceKey,
        result,
    };
};
exports.setValidationErrors = (validationErrors) => {
    return {
        type: constants_1.API_SET_VALIDATION_ERRORS,
        validationErrors,
    };
};
exports.setProgress = (key, progress) => {
    return {
        type: constants_1.API_SET_PROGRESS,
        key,
        progress,
    };
};
exports.callStoreAction = (actionType, payload = {}) => {
    if (actionType) {
        return {
            type: actionType,
            payload,
        };
    }
};
exports.apiInit = (payload) => apiVersionResolver(v0.init(payload), v1.init(payload), v1.initLegacy(payload));
exports.apiSharableInit = (token, payload) => apiVersionResolver(null, v1.sharableInit(token, payload));
exports.apiStatus = () => apiVersionResolver(v0.status(), v1.reInit());
exports.apiRefresh = () => apiVersionResolver(v0.refresh(), v1.refresh());
exports.apiSubmit = (payload) => apiVersionResolver(v0.submit(payload), v1.submit(payload));
exports.apiBack = () => apiVersionResolver(v0.back(), v1.back());
exports.apiFunction = (functionName, resultKey, payload) => apiVersionResolver(v0.apiFunction(functionName, resultKey, payload), v1.apiFunction(functionName, resultKey, payload));
exports.apiGetExportedNamespace = (namespaceName, namespaceKey) => apiVersionResolver(null, v1.apiGetExportedNamespace(namespaceName, namespaceKey));
exports.apiGetFile = (fileId) => apiVersionResolver(v0.getFile(fileId), v1.getFile(fileId));
const apiVersionResolver = (apiActionV0, apiActionV1, apiActionV1LegacyInit) => (dispatch, getState) => {
    if (getState().app.configuration.apiVersion === 'v0') {
        return dispatch(apiActionV0);
    }
    else {
        if (apiActionV1LegacyInit && getState().app.configuration.apiVersion === 'v1-legacy-init') {
            return dispatch(apiActionV1LegacyInit);
        }
        else {
            return dispatch(apiActionV1);
        }
    }
};
